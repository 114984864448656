class EcommerceMenu {

    constructor() {
        this.previousCurrency = $('.currency-selector-ecommerce-menu').val();
        this.decimalsQuantity = parseInt($('#decimalQuantity').val());
        this.roundConversionType = 1;
        this.init();
    }

    init() {
        this.addEcommerceMenuEvents();
        $('.widget-dayvo-cart-widget').removeClass('panel panel-default');
        $('.widget-dayvo-cart-widget').attr('id', 'containerWidgetDayvoCart');
        $('.widget-dayvo-cart-widget').children().removeClass('panel-body');
        if ($('#static-search-widget').val() != 'True') {
            $('.widget-dayvo-search-product-widget').closest('.layout-asidefirst').removeClass('col-md-3 col-xs-12').next().removeClass('col-md-9');
        }
    }

    addEcommerceMenuEvents() {
        const self = this;
        this.onCurrencyChange(self);
        this.onDocumentLoad(self);
        this.onMenuSearchClick(self);
        this.onMenuSearchKeyPress(self);
    }

    onDocumentLoad(self) {
        $(window).on('load', function (e) {
            //let previousCurrency = $(e.currentTarget).data('previous');
            let currencyValues = window.localStorage.getItem("currencyValues");
            if (currencyValues) {
                let parsedCurrencyValues = JSON.parse(currencyValues);
                if ($('.currency-selector-ecommerce-menu').val() != parsedCurrencyValues.targetCurrency.ISOCode) {
                    $('.currency-selector-ecommerce-menu').find('option[value="' + parsedCurrencyValues.targetCurrency.ISOCode + '"]').prop('selected', 'selected').change();
                }
                
            }

            if ($('#isEnabledCart').val() ? $('#isEnabledCart').val().trim().toLowerCase() != 'false' : false) {
                self.setCartProductsQuantity();
            }

            if ($('#isEnabledUserPanel').val() ? $('#isEnabledUserPanel').val().trim().toLowerCase() != 'false' : false) {
                self.setWishListQuantity();
            }
            
        });
    }

    onCurrencyChange(self) {

        $(document).on('change', '.currency-selector-ecommerce-menu', function (e) {
            //let previousCurrency = $(e.currentTarget).data('previous');
            
            let currency = $(e.currentTarget).val();
            
            self.saveCurrency(e, self, currency);
        });
    }

    onMenuSearchClick(self) {
        $(document).on('click', '#js-product-search-short-btn', function (e) {
            self.doSearch(self, e);
        });
    }

    onMenuSearchKeyPress(self) {
        $(document).on('keypress', '#pqshort', function (e) {
            if (e.keyCode == 13) {
                self.doSearch(self, e);
            }
        });
    }

    saveCurrency(e, self, currency) {
        let token = $('input[name=__RequestVerificationToken]').val();

        //FIRST OPTION TO CHANGE PRICES BY CURRENCY
        let currencyOptionElement = $('.currency-selector-ecommerce-menu').find('option').filter(function () {
            return $(this).text() === currency;
        });
        let currentCurrencyHasConversionRate = $(currencyOptionElement).data('has-conversion-rate');
        let currentCurrencyConversionRate = parseFloat($(currencyOptionElement).data('conversion-rate').toString().replace(',', '.'));

        self.decimalsQuantity = $(currencyOptionElement).data('should-show-decimals').toLowerCase() === 'true' ? $(currencyOptionElement).data('decimal-quantity') : 0;
        self.roundConversionType = $(currencyOptionElement).data('round-conversion-type');

        $.ajax({
            url: '/save-currency',
            method: 'POST',
            cache: false,
            async: true,
            data: {
                __RequestVerificationToken: token,
                currency: currency
            }
        }).done(function (result) {
            if (result.status == 200) {

                $(e.currentTarget).data('previous', currency);
                // This is to ever make the convertion with the original price
                self.getConvertionValues($('#optionLocalCurrency').val().trim(), currency).then(function (result) {

                    // By changing the currency, the value of supported decimals will also change
                    //$('#decimalQuantity').val();

                    let convertionValues = result.convertionValues;
                    let sourceIsoCodeModel = null;
                    let targetIsoCodeModel = null;

                    if (result.status == 200) {
                        if (convertionValues) {

                            window.localStorage.setItem("currencyValues", JSON.stringify(result.convertionValues));

                            for (const [key, value] of Object.entries(convertionValues)) {
                                if (key === 'sourceCurrency') {
                                    sourceIsoCodeModel = value;
                                }

                                if (key === 'targetCurrency') {
                                    targetIsoCodeModel = value;
                                }
                            }
                        }

                    }

                    self.changePriceValues(self, sourceIsoCodeModel, targetIsoCodeModel, currentCurrencyConversionRate, currentCurrencyHasConversionRate.toLowerCase());

                });
            } else {
                $('#optionLocalCurrency').prop('selected', 'selected').change();
            }
        }).fail(function (jqXHR, exception) {
        });
    }

    // FIRST OPTION TO CHANGE PRICES BY CURRENCY
    changePriceValues(self, sourceIsoCodeModel, targetIsoCodeModel, currencyConversionRate, currentCurrencyHasConversionRate) {

        //$('.currency').text(' ' + $('.currency-selector-ecommerce-menu').val().trim());
        $('.currency').text(' ' + $('.currency-selector-ecommerce-menu').find(":selected").data('symbol'));

            $('.price').each(function () {

                if ($(this).data('original-price')) {
                    let productId = $(this).data('product-id') || 0;
                    let starting_at = $('#itemMinProductPrimary' + productId).find('.def-price').data('has-price-per-quantity') === "True" ? $('#starting-at').val() : '';

                    if (!($('.currency-selector-ecommerce-menu').val().trim() === $('#optionLocalCurrency').val().trim()) && currentCurrencyHasConversionRate === 'true') {
                        let currentPrice = parseFloat($(this).data('original-price').toString().replace(',', '.'));
                        let changedPrice = currentPrice * currencyConversionRate;

                        if (self.roundConversionType === 'AlwaysRoundUp') {
                            changedPrice = Math.ceil(changedPrice);
                        }

                        if ($(this).is('input')) {

                            $(this).val(changedPrice);
                            $(this).trigger('change');

                        } else {
                            $(this).text(starting_at + self.getFormatedNumber(self,Number(changedPrice)));
                        }

                    } else {

                        let changedValue = parseFloat($(this).data('original-price').toString().replace(',', '.'));

                        if (sourceIsoCodeModel && targetIsoCodeModel) {
                            changedValue = changedValue * (sourceIsoCodeModel.ValueUSD / targetIsoCodeModel.ValueUSD);
                        }

                        if (self.roundConversionType === 'AlwaysRoundUp') {
                            changedValue = Math.ceil(changedValue);
                        }

                        if ($(this).is('input')) {

                            $(this).val(changedValue);
                            $(this).trigger('change');

                        } else {
                            $(this).text(starting_at + self.getFormatedNumber(self, Number(changedValue)));
                        }


                    }
                }
            });
        
    }

    getConvertionValues(sourceIsoCode, targetIsoCode) {

        return $.ajax({
            url: '/get-convertion-values',
            method: 'GET',
            cache: false,
            async: true,
            data: {
                sourceIsoCode: sourceIsoCode,
                targetIsoCode: targetIsoCode
            }
        }).done(function (result) {

        }).fail(function (jqXHR, exception) {
        });

       
    }

    setCartProductsQuantity() {

        if (!parseInt($('.cart-counter').text()) > 0) {
            $('.cart-counter').text('');
        }

        $.ajax({
            url: '/ecommerce/cart/get-cart-quantity',
            method: 'GET',
            cache: false
        }).done(function (result) {
            if (result.quantity > 0) {
                $('.cart-counter').text(result.quantity);
            } else {
                $('.cart-counter').text('');
            }
        }).fail(function (jqXHR, exception) {
            $('.cart-counter').text('');
        });

    }

    setWishListQuantity() {

        if (!parseInt($('.wish-list-counter').text()) > 0) {
            $('.cart-counter').text('');
        }

        $.ajax({
            url: '/ecommerce/cart/get-wish-list-quantity',
            method: 'GET',
            cache: false
        }).done(function (result) {
            if (result.quantity > 0) {
                $('.wish-list-counter').text(result.quantity);
            } else {
                $('.wish-list-counter').text('');
            }
        }).fail(function (jqXHR, exception) {
            $('.wish-list-counter').text('');
        });

    }

    doSearch(self, e) {
        let pq = $('#pqshort').val();
        window.location.href = window.location.protocol + "//" + window.location.host + "/ecommerce/search-product?pq=" + pq;
    }

    getFormatedNumber(self, number) {
        let currency = $('.currency-selector-ecommerce-menu').find(":selected").val();
        let currencyIsCOP = currency?.trim() === 'COP';
        return Intl.NumberFormat(currency === 'USD' ? 'en' : 'es', { minimumFractionDigits: !currencyIsCOP ? self.decimalsQuantity : 0, maximumFractionDigits: !currencyIsCOP ? self.decimalsQuantity : 0, useGrouping: true }).format(number);
    }

    getUrlParameterValue(name) {

        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        return decodeURI(results[1]) || 0;
    }
    
}



$(document).ready(() => { new EcommerceMenu(); });